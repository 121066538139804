const week1title = "Week 1 - Use a Password Manager";
const week1risk = "Account Takeover";
const week1action = `1. Install a password manager ([KeepassXC](https://keepassxc.org/)
is recommended) on your computer (and/or phone)
2. Save one account password in the password manager, close it, and reopen the file
3. Save all your existing account passwords in the password manager`;
const week1rationale = `Because passwords are the number 1 method that your
online accounts can be compromised, it is important to start here.
Using a password manager allows you to store complex passwords
that are not easy to guess (or remember). While the most convenient way to do this is
to store this information in a password manager program, it is also possible
to store this information in a physical notebook. However, unlike a physical notebook,
a password manager  encrypts your password data (which ironically requires a password to decrypt),
making sure that only you can access your password data. Storing your encrypted
password data on your own computer (or phone) is the recommended approach, because
many online password managers have been hacked before, such as
[Lastpass](https://www.wired.com/2015/06/hack-brief-password-manager-lastpass-got-breached-hard/),
[OneLogin](https://www.zdnet.com/article/onelogin-hit-by-data-breached-exposing-sensitive-customer-data/), and many others`;
const week2title = "Week 2 - Maintain Good Password Hygiene";
const week2risk = "Account Takeover";
const week2action = `1. Starting with your most important accounts (e.g. email,
financial accounts), change your password on all important online accounts to
use a 20 character or greater pseudorandom password generated by your password
manager. Store the new passwords in your password manager.`;
const week2rationale = `After creating a secure place to track all your passwords,
you should verify that you are using strong passwords. Several tips will
keep your password usage up to par:
1. Use a randomly generated password from your password manager. These are hard to guess
(and hard to remember), but they are easy to copy from your password manager and will give
you accounts better security.
2. Most importantly, do not use a common password! If you want to see what common
passwords look like, [this is one list](https://github.com/danielmiessler/SecLists/blob/master/Passwords/Common-Credentials/10-million-password-list-top-10000.txt)
of 10,000 of the most common passwords.
3. Don't reuse the same password for multiple accounts. If one of your passwords
gets compromised, you don't want that to impact multiple accounts!
4. Change your passwords regularly. For some paranoid people, this might mean every 90 days,
while other might be satisfied with a password change once per year`;
const week3title = "Week 3 - Enable 2 Factor Authentication (2FA)";
const week3risk = "Account Takeover";
const week3action = `1. Visit [2FA Directory](https://2fa.directory/) and identify
  which of your most important accounts (e.g. email,  financial accounts)
  support 2 factor authentication.
  2. Login to your important accounts and activate 2FA, preferably using a mobile
  phone authenticator app and not SMS authentication.`;
const week3rationale = `Many email and financial websites support 2 factor
authentication (2FA), which has been shown to dramatically increase the security
of your accounts. Because passwords are the number 1 weak point, 2FA adds
another layer of defense against unauthorized individuals trying to access your
account. Unfortunately, the most common 2FA approach, a mobile text message, is
the least secure 2FA method. The reason text message 2FA is insecure is because
of what's called a SIM swap attack. A SIM swap attack is where someone visits a
phone stores and claims to have lost their phone, so they need a new SIM card
with a specific phone number on it. With this process, another person can get a
SIM card that receives messages intended for your phone number, and therefore
can receive your 2FA text messages. It is much better to use a 2FA app such as
[Authy](https://authy.com/),
[Duo Mobile](https://duo.com/product/multi-factor-authentication-mfa/duo-mobile-app),
[Google Authenticator](https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US),
or a similar app from a well known and trusted company.`;
const week4title = "Week 4 - Reserve Important Accounts";
const week4risk = "Identity Theft";
const week4action = `Note: this week's advice is for US residents only
1. Create online accounts at the three main credit bureaus,
saving the passwords in your password manager
- [Equifax account](https://my.equifax.com/consumer-registration/UCSC/#/personal-info)
- [Experian account](https://www.experian.com/help/login.html)
- [Transunion account](https://service.transunion.com/dss/orderStep1_form.page?)
2. Create an online account with the IRS
- [IRS account](https://www.irs.gov/payments/view-your-tax-account)
3. Create an online account with the Social Security Administration
- [SSA account](https://secure.ssa.gov/RIL/SiView.action)
4. Create an online account with your state's unemployment office
5. Create an online account with your bank and other financial institutions
6. Create an online account with your internet provider and phone service provider`;
const week4rationale = `Identity theft is one of the most common ways that an
average person can be impacted by cybercriminals, partially because there are
many ways it can occur. One common way that identity theft happens is by opening
online accounts in someone else's name. This is how a lot of the unemployment fraud
occurred in 2020 (using newly created state unemployment accounts), and how tax
return fraud has been happening for years (using newly created IRS accounts).
Once one of your important accounts has been compromised, it is often possible
for the identity thief to then create more accounts in your name, and at worst,
open fraudulent lines of credit in your name. To prevent this from happening
to you, you will want to create a few online accounts in your own name to
"reserve" these accounts and prevent someone from opening your account for you! For more, see
[this post](https://krebsonsecurity.com/2020/08/why-where-you-should-you-plant-your-flag/)`;
const week5title = "Week 5 - Freeze your Credit";
const week5risk = "Financial Impact";
const week5action = `1. Freeze your credit with each of the 3 major credit
bureaus:
- [Equifax](https://www.equifax.com/personal/credit-report-services/credit-freeze/) (requires account login)
- [Experian](https://www.experian.com/freeze/center.html)
- [Transunion](https://www.transunion.com/credit-freeze) (requires account login)

Optional: If you are unable to login to your online accounts, or do not wish to,
most if not all credit bureaus have automated phone menus to allow you to freeze
your credit over the phone`;
const week5rationale = `Another important protection against identity theft is
freezing your credit at all three major credit bureaus. Previously people
actually had to pay a fee each time they froze their credit score, but after the
2017 Equifax hack, this service is now free.`;
const week6title = "Week 6 - Install Antivirus and Antimalware";
const week6risk = "Data Loss";
const week6action = `1. Install [Avast Antivirus](https://www.avast.com) on
  your computer to protect against computer viruses
  2. Install [Malwarebytes Antimalware](https://www.malwarebytes.com/) on your
    computer to protect against malware `;
const week6rationale = `Despite advanced in computer security, viruses, malware,
and ransomware are becoming ever more prevalent. There are many avenues through
which viruses and malware can end up on your device, and often you will not
notice anything is amiss until it may be too late. It is important to add
antivirus and antimalware protection against these threats to prevent your
computer or accounts from being taken over, ransomed, or destroyed.`;
const week7title = "Week 7 - Keep your Computer's Software Updated";
const week7risk = "Data Loss";
const week7action = `1. Think about which pieces of software you use most often.
Learn how to either manually install updates for this software or enable
automatic updates.
2. Open your computer's list of installed applications and sort by the date installed.
Make sure the older pieces of software installed are updated, if updates exist.`;
const week7rationale = `Outdated software is often vulnerable software.
Almost every commonly used program frequently has software vulnerabilities
(AKA security bugs) found in it, and these are usually published to alert program
users. The best way to stay on top of this continuous stream of software
vulnerabilities is to keep your computer as update-to-date as possible. This includes
the software you often use on your computer (web browser, photo viewer, etc.),
any background software (Java, etc.),
as well as the computer's operating system itself (regular Windows or Mac updates).`;
const week8title = "Week 8 - Uninstall Unnecessary Software from Computer";
const week8risk = "Data Loss";
const week8action = `1. For Windows users, visit the Control Panel and select
"Programs and Features" to view a list of installed software. Determine which
software on your computer is unused and can be uninstalled.
2. For Mac users, open the Applications folder to view a list of installed
software. Determine which software on your computer is unused and can be
moved to the trash.`;
const week8rationale = `Any unnecessary software on your device is a possible
avenue for problems. Unused software can sit untouched without receiving
updates, and `;
const week9title = "Week 9 - Regular Computer Data Backups";
const week9risk = "Data Loss";
const week9action = `1. Choose where you would prefer to backup your computer files.
The usual options are:
- a local external hard drive
- an online storage provider (AKA in the cloud)
2. Choose how often you want to back up your files. Set a regular calendar
reminder on your phone (or other calendar service) to perform a backup at this interval
3. Backup your files for this first time to identify any hiccups in your process`;
const week9rationale = `They say there are two types of people in the world -
those who have lost data and those who will lose data. The best and only way to
protect against losing your precious data is by backing it up regularly.`;
const week10title = "Week 10 - Improve your Web Browser's Security";
const week10risk = "Privacy Loss";
const week10action = `1. Follow the guides below`;
const week10rationale = `If you use Firefox, try out
[this guide](https://security.erikelbieh.com/secure-firefox-browser-1).
For Chrome user's, you might want to consider switching to Firefox, or alternatively
use [this guide](https://security.erikelbieh.com/secure-chrome-browser)`;
const week11title = "Week 11 - Install Security-related Browser Extensions";
const week11risk = "Privacy Loss";
const week11action = `Use `;
const week11rationale = `Coming soon...`;
const week12title = `Week 12 - Keep your Phone's Apps Updated`;
const week12risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week12action = `1. Do this`;
const week12rationale = `Just as it is important to keep your computer's software
updated with the latest version, it is also important to keep your phone apps updated.`;
const week13title = "Week 13 - Uninstall Unnecessary Apps from Phone";
const week13risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week13action = `1. Do this`;
const week13rationale = "Coming soon...";
const week14title = "Week 14 - Use Encrypted Messaging Apps";
const week14risk = "Data Loss";
const week14action = `1. Install the [Signal](https://www.signal.org/) app on
  your phone.
  2. Set Signal as your default messaging app and encourage your contacts to use
  it to communicate with you (for both texts and calls)`;
const week14rationale = `[Signal](https://www.signal.org/) is the best
choice among encrypted messaging apps, but
[Telegram](https://telegram.org/) is another popular option. Looking ahead to next week,
you should choose a messaging app that is both secure and allows for easy backups.
Unfortunately, Whatsapp was sold to
Facebook several years ago and recently privacy changes to Whatsapp no longer
make it a good choice. Soon after selling to Facebook, one of the [Whatsapp
cofounders joined Signal](https://www.wired.com/story/signal-foundation-whatsapp-brian-acton/).`;
const week15title = "Week 15 - Regular Phone Data Backups";
const week15risk = "Data Loss";
const week15action = `1. Connect your phone to your computer and copy your files
to a backup location (either your own hard drive or in the cloud)`;
const week15rationale = `Any important data you have should be backed up frequently.
Often phones can hold photos, videos, and text messages that their owners would prefer not to lose.`;
const week16title = "Week 16 - Switch Off Phone Radios";
const week16risk = "Privacy Loss";
const week16action = `1. Whenever you do not need your phone's wireless features,
put it in airplane mode. You can also turn off these features individually:
- WiFi
- Bluetooth
- Mobile data
- NFC
- GPS
- Any other wireless communication features
2. (optional) For the extra paranoid, buy a
[faraday cage case](https://www.amazon.com/Blocking-ONEVER-Protection-Anti-Tracking-Anti-Spying/dp/B07BQM75MH)
to store your phone when you are not actively using it`;
const week16rationale = `If you do not need to always use WiFi, Bluetooth, NFC, GPS,
or other wireless technologies on your phone, it is always a good idea to
switch them off. Not only will it provide additional security, but it also
improves your battery life`;
const week17title = "Week 17 - Enable Phone Data Storage Encryption";
const week17risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week17action = `1. Do this`;
const week17rationale = "Coming soon...";
const week18title = "Week 18 - Enable Strong Phone Lock";
const week18risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week18action = `1. Open your phone's settings and activate the screen lock
security feature. Choose a secure lock code that does not follow a simple pattern.`;
const week18rationale = `Consider the impact that the theft of your phone could
have on your other accounts or data. If you have 2 factor authentication (2FA)
apps on your phone, it is possible that the theft of your phone lead to account
takeovers of your most important accounts. To protect against this worst-case
scenario, protect your phone by adding a security screen lock so that your
phone's data is not easily access by unauthorized people.`;
const week19title = 'Week 19 - Clear the "remembered" WiFi networks';
const week19risk = "Data Loss";
const week19action = `1. Do this`;
const week19rationale = "Do this for both you computer as well as your phone.";
const week20title = "Week 20 - Use a VPN on Public WiFi";
const week20risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week20action = `1. Sign up for a paid no-logs VPN service, such as those suggested by
[this guide](https://www.privacyguides.org/providers/vpn/), this [comparison table](https://www.reddit.com/r/VPN/comments/ra75uq/monthly_vpn_recommendations_megathread_the_only/), or [this spreadsheet](https://www.reddit.com/r/hacking/comments/43cx3a/someone_told_me_that_you_guys_might_be_interested/)`;
const week20rationale = `If you are using a public WiFi hotspot and send confidential data to a website
that does NOT use HTTPS (encrypted HTTP), malicious users on the WiFi network can read the data you send
and potentially gian unauthorized access to your accounts (for example, with [Firesheep](https://en.wikipedia.org/wiki/Firesheep)).`;
const week21title = "Week 21 - Avoid Clicking Untrusted Email Links";
const week21risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week21action = `1. Do this`;
const week21rationale = "Coming soon...";
const week22title = "Week 22 - Delete and Wipe Data Securely";
const week22risk = "Privacy Loss";
const week22action = `1. To delete your data securely, or wipe a hard disk or
other storage device, follow the guide for your computer's operating system:
- For Windows users, follow [this guide](https://ssd.eff.org/en/module/how-delete-your-data-securely-windows)
- For MacOS users, follow [this guide](https://ssd.eff.org/en/module/how-delete-your-data-securely-macos)
- For Linux users, follow [this guide](https://ssd.eff.org/en/module/how-delete-your-data-securely-linux)`;
const week22rationale = "Coming soon...";
const week23title = "Week 23 - Prepare Plan B Strategies";
const week23risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week23action = `1. Maintain 3 copies or backups of your data, and keep 1 copy off-site
2. Don't use SMS text message 2FA if other options exist due to SIM-swapping risk
3. Use strong passwords on your portable electronics (phone, laptop, etc.) in case they are stolen`;
const week23rationale = `As the saying goes, "there are two types of people in the world:
those who have lost data and those who will lose data". By having a plan before a worst-case
privacy event happens, you can put safeguards in place. For example, if you anticipate the
consequences of a SIM-swapping attack, you might move accounts to 2FA using a hardware or
software token instead.`;
const week24title = "Week 24 - Enable Computer Hard Drive Encryption";
const week24risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week24action = `1. Do this`;
const week24rationale = "Coming soon...";
const week25title = "Week 25 - Sign Up for Credit Monitoring";
const week25risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week25action = `1. Do this`;
const week25rationale = "Coming soon...";
const week26title = "Week 26 - Sign Up for Property Fraud Alerts";
const week26risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week26action = `1. Sign up for property fraud alerts [here](https://www.propertyfraudalert.com/)`;
const week26rationale = `Identity theft can involve even higher stakes when it
involves property that you live in or own. The USA has had a large enough problem
with real estate fraud that you can sign up for alerts whenever your local county
processes legal paperwork with your name on it.`;
const week27title = "Week 27 - Review Financial Statements for Fraud";
const week27risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week27action = `1. Do this`;
const week27rationale = "Coming soon...";
const week28title = "Week 28 - Enable Financial Account Alerts";
const week28risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week28action = `1. Do this`;
const week28rationale = "Coming soon...";
const week29title = "Week 29 - Limit Data Sharing for Online Accounts";
const week29risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week29action = `1. Do this`;
const week29rationale = `Especially important for big data aggregators like Google
and Facebook, but also important for finance-related websites that can leak
information such as Venmo.`;
const week30title = "Week 30 - Use Throwaway Email Accounts";
const week30risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week30action = `1. Do this`;
const week30rationale = `If you want to access a website that requires an account
but don't want to use your primary personal email account, there are several
options. One is to use a website like bugmenot.com, where users upload their
temporary accounts for anyone to use. If you don't want to use a shared account
or there are none available, there are many websites that allow you to use a temporary email
address to register for an account that you don't plan to keep using. One website to get a temporary gmail email address is
[gmailnator](https://gmailnator.com/), and
[10minutemail](https://10minutemail.com/) is another similar site.`;
const week31title = "Week 31 - Cover Camera on Computer and Phone";
const week31risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week31action = `1. Do this`;
const week31rationale = `While you might already have a sticker covering the webcam
camera on your computer, you can consider covering the camera on your phone as
well. A new phone case design provides a slideable cover built-in to the phone
case, both to protect your phone camera and to provide extra protection from
prying eyes. Cases for many popular phones, including
[Apple iPhones](https://www.amazon.com/Ownest-Compatible-Protection-Lightweight-Anti-Yellow/dp/B0881P79M6/ref=sr_1_6) and
[Samsung Android](https://www.amazon.com/Nillkin-CamShield-Protective-Protector-Anti-Scratch/dp/B08R3MGX17/ref=sr_1_11) devices`;
const week32title = "Week 32 - Remove Metadata from Shared Photos";
const week32risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week32action = `1. Use an app such as
[Scrambled Exif](https://f-droid.org/en/packages/com.jarsilio.android.scrambledeggsif/) before sharing photos`;
const week32rationale = "Coming soon...";
const week33title = "Week 33 - Coming soon...";
const week33risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week33action = `1. Do this`;
const week33rationale = "Coming soon...";
const week34title = "Week 34 - Coming soon...";
const week34risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week34action = `1. Do this`;
const week34rationale = "Coming soon...";
const week35title = "Week 35 - Coming soon...";
const week35risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week35action = `1. Do this`;
const week35rationale = "Coming soon...";
const week36title = "Week 36 - Coming soon...";
const week36risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week36action = `1. Do this`;
const week36rationale = "Coming soon...";
const week37title = "Week 37 - Coming soon...";
const week37risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week37action = `1. Do this`;
const week37rationale = "Coming soon...";
const week38title = "Week 38 - Coming soon...";
const week38risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week38action = `1. Do this`;
const week38rationale = "Coming soon...";
const week39title = "Week 39 - Coming soon...";
const week39risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week39action = `1. Do this`;
const week39rationale = "Coming soon...";
const week40title = "Week 40 - Coming soon...";
const week40risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week40action = `1. Do this`;
const week40rationale = "Coming soon...";
const week41title = "Week 41 - Coming soon...";
const week41risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week41action = `1. Do this`;
const week41rationale = "Coming soon...";
const week42title = "Week 42 - Coming soon...";
const week42risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week42action = `1. Do this`;
const week42rationale = "Coming soon...";
const week43title = "Week 43 - Coming soon...";
const week43risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week43action = `1. Do this`;
const week43rationale = "Coming soon...";
const week44title = "Week 44 - Coming soon...";
const week44risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week44action = `1. Do this`;
const week44rationale = "Coming soon...";
const week45title = "Week 45 - Coming soon...";
const week45risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week45action = `1. Do this`;
const week45rationale = "Coming soon...";
const week46title = "Week 46 - Coming soon...";
const week46risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week46action = `1. Do this`;
const week46rationale = "Coming soon...";
const week47title = "Week 47 - Remove Finance Apps from Smartphones";
const week47risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week47action = `1. Uninstall any finance-related apps (banking apps, trading apps,
  Venmo, etc.) from your mobile device`;
const week47rationale = `Your portable electronics, especially your smartphone,
is always at risk of being lost or stolen. You can minimize the damage that smartphone
theft may have by removing all apps that handle financial transactions, store important
account details, or otherwise impact your financial wellbeing.`;
const week48title = "Week 48 - (Advanced) Remove IoT Devices";
const week48risk = "Privacy Loss, Data Loss";
const week48action = `1. Walk around your living space and make note of any "smart"
electronics or WiFi-connected devices
2. Consider the cost/benefit of each "smart" connected device and remove those that do
not provide benefits that outweigh the potential risks
3. Before purchasing new devices, seriously considering buying the "dumb" version of
the device and not the "smart" connected (and more easily hacked) version of the device.
This is especially true for smart TVs and smart kitchen appliances, which are often insecure`;
const week48rationale = `Unfortunately most smart devices or Internet of Things gadgets
have a reputation for poor levels of security. If you have a few of these devices
and are willing to switch to the "dumb" equivalent of the device, it would remove
one potential security target in your living space.`;
const week49title = "Week 49 - Plan for Medical Emergency Scenarios";
const week49risk =
  "Account Takeover, Financial Impact, Data Loss";
const week49action = `1. Discuss alternative account access strategies with a trusted party`;
const week49rationale = `If you are in a medical emergency that leaves you unable to remember your account
credentials, or perhaps are seriously injured and are unconscious for a lengthy period of time, is there
anyone else who relies on the data in your accounts for going about their lives? If you have dependents,
creating a backup plan with a trusted party for managing bank balances and other important information
could make a large difference in the event that you are not able to access your own accounts for one reason
or another. To go even further, some accounts have settings to plan for the owner's eventual death,
such as [Google's Inactive Account Manager feature](https://support.google.com/accounts/troubleshooter/6357590?hl=en)`;
const week50title = "Week 50 - (Advanced) Opt Out of Data Collection";
const week50risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week50action = `1. Follow the instructions in various data broker opt-out lists`;
const week50rationale = `Many websites collect your data, but there are ways to
opt out of this data collection. Unfortunately this can be a bit of a chore,
but [this list](https://github.com/yaelwrites/Big-Ass-Data-Broker-Opt-Out-List)
should get you pretty far. If you don't want to spend your time on this process,
there are paid services that go through this process for you.`;
const week51title = "Week 51 - (Advanced) Update Device Firmware";
const week51risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week51action = `1. Walk around your living space and make note of the model number of electronic device
2. Research whether each electronic device can receive firmware updates. At a minimum, consider updating your WiFi router and printer`;
const week51rationale = `Internet routers, printers, and other devices you might own
often get software updates after they are first released (depending on the
manufacturer of the equipment). You can often find the latest firmware for
these devices on the manufacturer's website.`;
const week52title = "Week 52 - (Advanced) Use Advanced Privacy Tools";
const week52risk =
  "Identity Theft, Account Takeover, Financial Impact, Data Loss";
const week52action = `1. Follow other privacy guides to increase your overall digital privacy`;
const week52rationale = `There is no shortage of more advanced privacy tools
out there, with new ones popping up frequently. Two good reference websites
include [privacytools.io](https://privacytools.io/) and
[datadetoxkit](https://datadetoxkit.org/en/home)`;
export const weeksTitles = [
  week1title,
  week2title,
  week3title,
  week4title,
  week5title,
  week6title,
  week7title,
  week8title,
  week9title,
  week10title,
  week11title,
  week12title,
  week13title,
  week14title,
  week15title,
  week16title,
  week17title,
  week18title,
  week19title,
  week20title,
  week21title,
  week22title,
  week23title,
  week24title,
  week25title,
  week26title,
  week27title,
  week28title,
  week29title,
  week30title,
  week31title,
  week32title,
  week33title,
  week34title,
  week35title,
  week36title,
  week37title,
  week38title,
  week39title,
  week40title,
  week41title,
  week42title,
  week43title,
  week44title,
  week45title,
  week46title,
  week47title,
  week48title,
  week49title,
  week50title,
  week51title,
  week52title,
];
export const weeksRisks = [
  week1risk,
  week2risk,
  week3risk,
  week4risk,
  week5risk,
  week6risk,
  week7risk,
  week8risk,
  week9risk,
  week10risk,
  week11risk,
  week12risk,
  week13risk,
  week14risk,
  week15risk,
  week16risk,
  week17risk,
  week18risk,
  week19risk,
  week20risk,
  week21risk,
  week22risk,
  week23risk,
  week24risk,
  week25risk,
  week26risk,
  week27risk,
  week28risk,
  week29risk,
  week30risk,
  week31risk,
  week32risk,
  week33risk,
  week34risk,
  week35risk,
  week36risk,
  week37risk,
  week38risk,
  week39risk,
  week40risk,
  week41risk,
  week42risk,
  week43risk,
  week44risk,
  week45risk,
  week46risk,
  week47risk,
  week48risk,
  week49risk,
  week50risk,
  week51risk,
  week52risk,
];
export const weeksActions = [
  week1action,
  week2action,
  week3action,
  week4action,
  week5action,
  week6action,
  week7action,
  week8action,
  week9action,
  week10action,
  week11action,
  week12action,
  week13action,
  week14action,
  week15action,
  week16action,
  week17action,
  week18action,
  week19action,
  week20action,
  week21action,
  week22action,
  week23action,
  week24action,
  week25action,
  week26action,
  week27action,
  week28action,
  week29action,
  week30action,
  week31action,
  week32action,
  week33action,
  week34action,
  week35action,
  week36action,
  week37action,
  week38action,
  week39action,
  week40action,
  week41action,
  week42action,
  week43action,
  week44action,
  week45action,
  week46action,
  week47action,
  week48action,
  week49action,
  week50action,
  week51action,
  week52action,
];
export const weeksRationales = [
  week1rationale,
  week2rationale,
  week3rationale,
  week4rationale,
  week5rationale,
  week6rationale,
  week7rationale,
  week8rationale,
  week9rationale,
  week10rationale,
  week11rationale,
  week12rationale,
  week13rationale,
  week14rationale,
  week15rationale,
  week16rationale,
  week17rationale,
  week18rationale,
  week19rationale,
  week20rationale,
  week21rationale,
  week22rationale,
  week23rationale,
  week24rationale,
  week25rationale,
  week26rationale,
  week27rationale,
  week28rationale,
  week29rationale,
  week30rationale,
  week31rationale,
  week32rationale,
  week33rationale,
  week34rationale,
  week35rationale,
  week36rationale,
  week37rationale,
  week38rationale,
  week39rationale,
  week40rationale,
  week41rationale,
  week42rationale,
  week43rationale,
  week44rationale,
  week45rationale,
  week46rationale,
  week47rationale,
  week48rationale,
  week49rationale,
  week50rationale,
  week51rationale,
  week52rationale,
];
