import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import ReactGA from "react-ga";

export default function About() {
  ReactGA.initialize(
    "UA-200014998-1",
    {
      gaOptions: { siteSpeedSampleRate: 100 },
    }
  );
  ReactGA.pageview('/about');

  return (
    <React.Fragment>
      <Container maxWidth="md">
        <br />
        <br />
        <Typography
          component="h2"
          variant="h3"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          About
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          This website is designed to answer the question "how can I secure my
          digital data and improve my privacy?" The website's author inhabits in
          the information security space and has frequently received this
          question from friends. This guide is intended to be clear and
          actionable. By following these steps, you will be more protected
          against identity theft, account hacks, and be prepared if unforeseen
          events do occur.
        </Typography>
      </Container>
    </React.Fragment>
  );
}
