import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 600,
  },
}));

export default function FAQ() {
  ReactGA.initialize(
    "UA-200014998-1",
    {
      gaOptions: { siteSpeedSampleRate: 100 },
    }
  );
  ReactGA.pageview('/faq');
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container maxWidth="md">
        <br />
        <br />
        <Typography
          component="h2"
          variant="h3"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          FAQ
        </Typography>
        <Typography
          variant="h4"
          align="center"
          className={classes.bold}
          color="textPrimary"
          paragraph
        >
          1. Where do I begin?
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Fortunately, this list of 52 personal security improvements is sorted
          by priority. You can begin with week #1 and continue as far as you see
          fit for your situation.
        </Typography>
        <Typography
          variant="h4"
          align="center"
          className={classes.bold}
          color="textPrimary"
          paragraph
        >
          2. Why use the term "personal security"?
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          The security of your information pertains to you, so it's personal!
          You can think of it as a security analogy of the term "personal
          finance".
        </Typography>
        <Typography
          variant="h4"
          align="center"
          className={classes.bold}
          color="textPrimary"
          paragraph
        >
          3. Isn't this long list overkill?
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          It depends what your goals are - perhaps it is overkill! It can be
          hard to gauge whether you have the right amount of personal security
          measures in place. The usual time you will learn about your security
          stance is when you encounter an unfortunate event revealing you didn't
          have enough personal security to prevent the event. So from that
          perspective, it's better to be overprepared than underprepared.
        </Typography>

        <Typography
          variant="h4"
          align="center"
          className={classes.bold}
          color="textPrimary"
          paragraph
        >
          4. I am confused. Where can I find additional information?
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          You can either search on [DuckDuckGo](https://duckduckgo.com/) to get
          more information, try visiting one of the recommended websites in the
          list below, or use the Contact button at the top of the screen.
          <br />
          Recommended websites for additional information:
        </Typography>
      </Container>
    </React.Fragment>
  );
}
