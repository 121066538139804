import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  CssBaseline,
  Drawer,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as constantsFile from "../components/Constants";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import ReactGA from "react-ga";
import withTracker from './withTracker';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  customAccordion: {
    flexBasis: "33.33%",
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightLight,
  },
  toolbar: {
    backgroundColor: theme.palette.background.paper,
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <a href="https://erikelbieh.com/">Erik Elbieh</a>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

function WeeksInfo(props) {
  const accordion_bool = props.value;
  if (accordion_bool) {
    return <WeeksInfoAccordion />;
  }
  return (
    <WeeksInfoCard
      titles={props.titles}
      tips={props.tips}
      class={props.class}
    />
  );
}

function myalert() {
  if (window.confirm("Not implemented yet, coming soon..."))
    this.onCancel("bye");
}

function WeeksInfoCard(props) {
  ReactGA.event({
    category: "Home",
    action: "View #2 (cards) selected",
  });

  return (
    <React.Fragment>
      <Container className="cardGrid" maxWidth="md" spacing={4}>
        <Grid container>
          {constantsFile.weeksTitles.map((value, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card className="card">
                <CardMedia
                  className="cardMedia"
                  image="https://avatars.githubusercontent.com/u/6261182?v=4"
                  title="Image title"
                />
                <CardContent className="cardContent">
                  <Typography gutterBottom variant="h5" component="h2">
                    {constantsFile.weeksTitles[index]}
                  </Typography>
                  <Button size="small" color="primary" onClick={myalert}>
                    View
                  </Button>
                </CardContent>
                <CardActions></CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

function WeeksInfoAccordion(props) {
  const classes = useStyles();
  const risk = `**Primary Risks:**`;
  const action = `**Actions:**`;
  const rationale = `**Rationale:**`;

  return (
    <React.Fragment>
      {constantsFile.weeksTitles.map((value, index) => (
        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel-header"
          >
            <Typography align="left" className={classes.customAccordion}>
              {constantsFile.weeksTitles[index]}
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {/* Easy/Intermediate/Advanced value can go here */}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  <TableRow key={index + "a"}>
                    <TableCell component="th" scope="row">
                      <ReactMarkdown children={risk} />
                    </TableCell>
                    <TableCell align="left">
                      <ReactMarkdown
                        children={constantsFile.weeksRisks[index]}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={index + "b"}>
                    <TableCell component="th" scope="row">
                      <ReactMarkdown children={action} />
                    </TableCell>
                    <TableCell align="left">
                      <ReactMarkdown
                        children={constantsFile.weeksActions[index]}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={index + "c"}>
                    <TableCell component="th" scope="row">
                      <ReactMarkdown children={rationale} />
                    </TableCell>
                    <TableCell align="left">
                      <ReactMarkdown
                        children={constantsFile.weeksRationales[index]}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
    </React.Fragment>
  );
}

export default function Home() {
  ReactGA.initialize(
    "UA-200014998-1",
    {
      gaOptions: { siteSpeedSampleRate: 100 },
    }
  );
  ReactGA.pageview('/home');
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              The Personal Security Guide
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              paragraph
            >
              Your data, identity, online accounts, and financial data is
              valuable. Protecting this information is important, but not easy.
              This guide simplifies the process into weekly tasks to secure your
              life, one step at a time
            </Typography>

            <div className={classes.heroButtons}>
              <Grid container spacing={4} justify="center">
                <Grid item>
                  <Link to="/">
                    <Button
                      className={classes.heroButtons}
                      variant="contained"
                      color="primary"
                    >
                      View #1
                    </Button>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/view2">
                    <Button
                      className={classes.heroButtons}
                      variant="outlined"
                      color="primary"
                    >
                      View #2
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <React.Fragment>
          <Route exact path="/" component={() => <WeeksInfo value={true} />} />
          <Route path="/view2" component={() => <WeeksInfo value={false} />} />
        </React.Fragment>
      </main>

      {/* Footer */}
      <footer className={classes.footer}>
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}
