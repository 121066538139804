import "./App.css";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import Home from "./components/Home";
import About from "./components/About";
import FAQ from "./components/FAQ";
import ReactGA from "react-ga";
import withTracker from './components/withTracker';
require("dotenv").config();

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: green[500],
    },
    whitefont: {
      main: "#fafafa",
    },
  },
});

function App() {
  ReactGA.initialize(
    "UA-200014998-1",
    {
      gaOptions: { siteSpeedSampleRate: 100 },
    }
  );
  {
    /*
  <Helmet>
    <meta charSet="utf-8" />
    <title>The Personal Security Guide</title>
    <meta name="description" content="A guide to upgrade your personal security, information security, and privacy in one year with weekly actionable improvement steps.">
    <link rel="canonical" href="https://personalsecurity.erikelbieh.com/" />
  </Helmet>
  */
  }
  return (
    <Router>
      <ThemeProvider theme={customTheme}>
        <AppBar position="static">
          <Toolbar>
            <Button component={RouterLink} to="/">
              <Typography
                variant="button"
                display="block"
                style={{ marginRight: 20 }}
                noWrap
              >
                Home
              </Typography>
            </Button>
            <Button component={RouterLink} to="/about">
              <Typography
                variant="button"
                display="block"
                style={{ marginRight: 20 }}
                noWrap
              >
                About
              </Typography>
            </Button>
            <Button component={RouterLink} to="/faq">
              <Typography
                variant="button"
                display="block"
                style={{ marginRight: 20 }}
                noWrap
              >
                FAQ
              </Typography>
            </Button>
            <a href="https://erikelbieh.com/contact">
              <Button>
                <Typography variant="button" display="block" noWrap>
                  Contact
                </Typography>
              </Button>
            </a>
          </Toolbar>
        </AppBar>

        <Switch>
          <Route path="/about" component={withTracker('/about')}>
            <About />
          </Route>
          <Route path="/faq" component={withTracker('/faq')}>
            <FAQ />
          </Route>
          <Route path="/" component={withTracker('/homepage')}>
            <Home />
          </Route>
        </Switch>
      </ThemeProvider>
    </Router>
  );
}

export default App;
